export const environment = {
    logLevel: 'debug',
    production: false,
    stage: true,
    sourceMap: true,
    appVersion: '1.0.0',
    origin: "https://app.backend.farheenacademy.com/stage",
    appUrl: "https://app.stage.farheenacademy.com",
    socketOrigin: "wss://1dra6qfzddaaaa.execute-api.ap-southeast-1.amazonaws.com/stage",
    bucketUrl: "https://online-education-staging-resources.s3.ap-southeast-1.amazonaws.com",
    cdnUrl: "",
    vapidKey: "BP1mVU-oBoplZiZzNkUzA5LcPP8ujiWbS0yZgm2QbBBhew9F59R21MXZb7i002JfihBz7jc8I5ITsQYVZ-EVvk0",
    googleClientId: "423057939767-4smipfn7q9qfmvn8p9ku121ch4kmmned.apps.googleusercontent.com"
};
